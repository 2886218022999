<template>
    <div class="container ">
        <div class="row">
            <div class="col-sm-12 header_margin_employer"></div>
            <div class="col-sm-12">
                <div class="brigad_container">
                    <div class="zuvulguu_medeelel">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="zuvulguu_medeelel_slide">
                                    <Tabs value="name1">
                                        <!-- БАЙГУУЛЛАГА TAB-->
                                        <TabPane :label="'baiguullaga' | translate" name="name1">
                                            <div class="tabs">
                                                <div class="company_list">
                                                    <div v-if="loading" class="loading-wrap" >
                                                        <div class="lds-ellipsis">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row ">
                                                        <div class="col-sm-4">
                                                            <div class="left_col">
                                                                <div class="brgd_leftcol_title">
                                                                    <img src="../../assets/ajil_olgogch_icon.svg"
                                                                         alt="Icon">
                                                                    <span> {{ 'baiguullaga' | translate }}</span>
                                                                    <svg class="svg_text_line">
                                                                        <line x1="80"
                                                                              style="stroke:#EFBC11; stroke-width:3"/>
                                                                    </svg>
                                                                </div>
                                                                <div class="cleaner_btn_cont">
                                                                    <a href="javascript:void(0)"
                                                                       @click="cleanFilterBaiguullaga"
                                                                       class="clean_button_ao">
                                                                        <span>{{ 'clear' | translate }}</span>
                                                                    </a>
                                                                </div>
                                                                <div class="tab_main">
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'employerStatus' | translate }}</b></p>
                                                                    <Select class="job_select" v-model="selectTuluv"
                                                                            :placeholder="'Choose_status' | translate">
                                                                        <Option v-for="item in ajil_olgogch_tuluw"
                                                                                :value="item.id" :key="item.id">
                                                                            {{ item.tolov }}
                                                                        </Option>
                                                                    </Select>
                                                                    <hr>
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'direction_activity' | translate }}</b>
                                                                    </p>
                                                                    <Select class="job_select"
                                                                            v-model="selectVilAjilgaa"
                                                                            :placeholder="'Choose_field_activity' | translate" filterable>
                                                                        <!--<Option  :value="0" :key="0" style="color: #9a9a9a;"> Бүгд </Option>-->
                                                                        <Option v-for="item in vil_a_chiglel"
                                                                                :value="item.id" :key="item.id">
                                                                            {{ item.uilajilgaa }}
                                                                        </Option>
                                                                    </Select>
                                                                    <hr/>
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'aimag_niislel' | translate }}</b></p>
                                                                    <Select class="job_select"
                                                                            v-model="selectAimagNiislel"
                                                                            on-select="getSumDuureg"
                                                                            :placeholder="'aimagcapitalcity' | translate">
                                                                        <Option v-for="item in aimag_niislel"
                                                                                :value="item.id" :key="item.id">
                                                                            {{ item.aimagname }}
                                                                        </Option>
                                                                    </Select>
                                                                    <hr/>
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'sum_duureg' | translate }}</b></p>
                                                                    <Select class="job_select" v-model="selectSumDuureg"
                                                                            :placeholder="'sumdistrict' | translate">
                                                                        <!--<Option  :value="0" :key="0" style="color: #9a9a9a;"> Бүгд </Option>-->
                                                                        <Option v-for="item in SumDvvreg"
                                                                                :value="item.id"
                                                                                :key="item.id">
                                                                            {{ item.sumname }}
                                                                        </Option>
                                                                    </Select>
                                                                    <div class="search">
                                                                        <Button @click="getBaiguullagaSrchMulti"
                                                                                class="search_btn"><i
                                                                                class="ivu-icon ivu-icon-ios-search"></i>{{ 'search' | translate
                                                                            }}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-8 padd_left0">
                                                            <div class="ajil_olgogch_gvitsedgegch">
                                                                <div class="col-md-12">
                                                                    <div class="companies">
                                                                        <ul class="logo-group">

                                                                            <!--logo-items-->
                                                                            <li class="logo-item"
                                                                                v-for="(items,  index) in baiguullaga_lists"
                                                                                :key="index">

                                                                                <div v-if="items.torolgishuun">
                                                                                    <img src="../../assets/pic/star.svg"
                                                                                         class="left " alt="">
                                                                                </div>
                                                                                <div v-else>
                                                                                    <img src="../../assets/pic/star.svg"
                                                                                         class="left " alt=""
                                                                                         v-if="items.gishuunbus_torol === 'Алтан багц'">
                                                                                </div>
                                                                                <div v-if="items.torolgishuun">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/1_gold.svg"
                                                                                         alt=""
                                                                                         v-if="items.torolgishuun === 'Алтан багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/1_silver.svg"
                                                                                         alt=""
                                                                                         v-if="items.torolgishuun === 'Мөнгөн багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/1_bronze.svg"
                                                                                         alt=""
                                                                                         v-if="items.torolgishuun === 'Хүрэл багц'">
                                                                                </div>
                                                                                <div v-else>
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/2_gold.svg"
                                                                                         alt=""
                                                                                         v-if="items.gishuunbus_torol === 'Алтан багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/2_silver.svg"
                                                                                         alt=""
                                                                                         v-if="items.gishuunbus_torol === 'Мөнгөн багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/2_bronze.svg"
                                                                                         alt=""
                                                                                         v-if="items.gishuunbus_torol === 'Хүрэл багц'">
                                                                                </div>

                                                                                <div class="company_logo">
                                                                                    <img :src="image_url_mbua+items.logo"
                                                                                         alt="">
                                                                                </div>
                                                                                <div class="company_name">
                                                                                    {{ items.ner_mn }}
                                                                                </div>
                                                                                <router-link
                                                                                        :to="'/baiguullaga_tanilts/' + items.id">
                                                                                    <div class="hover_bg">
                                                                                    </div>
                                                                                </router-link>
                                                                                <!--<Button class="brgd_follow_btn_cont" href="/">-->
                                                                                <!--<img src="../../assets/pic/follow_btn.svg" alt="">-->
                                                                                <!--</Button>-->
                                                                                <!--<follow_btn :company_id="items.id" class="brgd_follow_btn_cont" type="baiguullaga"></follow_btn>-->
                                                                                <baiguullaga_follow_btn
                                                                                        class="brgd_follow_btn_cont"
                                                                                        :company_id="items.id"
                                                                                        type="baiguullaga">
                                                                                </baiguullaga_follow_btn>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="pagenation"
                                                                         style="width: 100%; float: left; margin-top: 10px;">
                                                                        <Page @on-change="baiguullagaPaginate"
                                                                              :current-page="current_page_ga"
                                                                              :page-size="16"
                                                                              :total="total_ga"
                                                                              size="small"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <!-- БАЙГУУЛЛАГА TAB-->

                                        <!-- БРИГАД TAB-->
                                        <TabPane :label="'brigad' | translate" name="name2">
                                            <div class="tabs">
                                                <div class="company_list">
                                                    <div v-if="loadingBrgd" class="loading-wrap">
                                                        <div class="lds-ellipsis">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row ">
                                                        <div class="col-sm-4">
                                                            <div class="left_col">
                                                                <div class="brgd_leftcol_title">
                                                                    <img src="../../assets/ajil_olgogch_icon.svg"
                                                                         alt="Icon">
                                                                    <span>{{ 'brigad' | translate }}</span>
                                                                    <svg class="svg_text_line">
                                                                        <line x1="80"
                                                                              style="stroke:#EFBC11; stroke-width:3"/>
                                                                    </svg>
                                                                </div>

                                                                <div class="cleaner_btn_cont">
                                                                    <a href="javascript:void(0)"
                                                                       @click="cleanFilterBrigad"
                                                                       class="clean_button_ao">
                                                                        <span>{{ 'clear' | translate }}</span>
                                                                    </a>
                                                                </div>
                                                                <div class="tab_main">
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'direction_activity' | translate }}</b>
                                                                    </p>
                                                                    <Select class="job_select"
                                                                            v-model="selectVilAjilgaaBrgd"
                                                                            :placeholder="'Choose_field_activity' | translate" filterable>
                                                                        <Option v-for="(item, index) in vil_a_chiglel_brgd"
                                                                                :value="item.brigad_id" :key="index">
                                                                            {{ item.brigad_uil_ajilgaa_chiglelcol }}
                                                                        </Option>
                                                                    </Select>
                                                                    <hr/>
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'aimag_niislel' | translate }}</b></p>
                                                                    <Select class="job_select"
                                                                            v-model="selectAimagNiislelBrgd"
                                                                            on-select="getSumDuuregBrgd"
                                                                            :placeholder="'aimagcapitalcity' | translate">
                                                                        <Option v-for="(item, index) in aimag_niislel"
                                                                                :value="item.id" :key="index">
                                                                            {{ item.aimagname }}
                                                                        </Option>
                                                                    </Select>
                                                                    <hr/>
                                                                    <p class="left_col_titile">
                                                                        <b>{{ 'sum_duureg' | translate }}</b></p>
                                                                    <Select class="job_select"
                                                                            v-model="selectSumDuuregBrgd"
                                                                            :placeholder="'sumdistrict' | translate">
                                                                        <Option v-for="(item, index) in SumDvvregForBrgdSrch"
                                                                                :value="item.id"
                                                                                :key="index">
                                                                            {{ item.sumname }}
                                                                        </Option>
                                                                    </Select>
                                                                    <div class="search">
                                                                        <Button @click="getBrigadSrchMultiCondition"
                                                                                class="search_btn"><i
                                                                                class="ivu-icon ivu-icon-ios-search"></i>{{ 'search' | translate
                                                                            }}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-8 padd_left0">
                                                            <div class="ajil_olgogch_gvitsedgegch">
                                                                <div class="col-md-12">
                                                                    <div class="companies">
                                                                        <ul class="logo-group">
                                                                            <li class="logo-item"
                                                                                v-for="(items,  index) in brigad_list"
                                                                                :key="index">
                                                                                <div v-if="items.torol_gishuun">
                                                                                    <img src="../../assets/pic/star.svg"
                                                                                         class="left " alt="">
                                                                                </div>
                                                                                <div v-else>
                                                                                    <img src="../../assets/pic/star.svg"
                                                                                         class="left " alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Алтан багц'">
                                                                                </div>
                                                                                <div v-if="items.torol_gishuun">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/1_gold.svg"
                                                                                         alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Алтан багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/1_silver.svg"
                                                                                         alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Мөнгөн багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/1_bronze.svg"
                                                                                         alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Хүрэл багц'">
                                                                                </div>
                                                                                <div v-else>
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/2_gold.svg"
                                                                                         alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Алтан багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/2_silver.svg"
                                                                                         alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Мөнгөн багц'">
                                                                                    <img class="logo right"
                                                                                         src="../../assets/company_logo/2_bronze.svg"
                                                                                         alt=""
                                                                                         v-if="items.torol_gishuun_bus === 'Хүрэл багц'">
                                                                                </div>
                                                                                <div class="company_logo">
                                                                                    <img :src="image_url_mbua+items.logo"
                                                                                         alt="">
                                                                                </div>
                                                                                <div class="company_name">
                                                                                    {{ items.ner_mn }}
                                                                                </div>
                                                                                <router-link
                                                                                        :to="'/brgd_tanilts/' + items.id">
                                                                                    <div class="hover_bg">
                                                                                    </div>
                                                                                </router-link>
                                                                                <brigad_follow_btn
                                                                                        class="brgd_follow_btn_cont"
                                                                                        :company_id="items.id"
                                                                                        type="brigad"></brigad_follow_btn>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="pagenation"
                                                                         style="width: 100%; float: left; margin-top: 10px;">
                                                                        <Page @on-change="brigadPaginate"
                                                                              :current-page="current_page_brgd"
                                                                              :page-size="16"
                                                                              :total="total_brgd"
                                                                              size="small"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    IMAGE_URL,
    AJIL_OLGOGCH_TULUV,
    GET_UIL_AJIGAA_CHIGLEL,
    GET_AIMAG_NIISLEL,
    GET_BAIGUULLAGA_SRCH_MULTI_CONDITION,
    GET_BAIGUULLAGA_SRCH_ALL_AOP,
    GET_SUM_DUUREG_ALL,
    GET_UIL_AJIGAA_CHIGLEL_BRIGAD,
    GET_BRIGAD_SRCH_MULTI_CONDITION,
    GET_BRIGAD_SRCH_ALL_AOP,
} from "../../graphql/queries";
import {mapGetters} from "vuex";
// const follow_btn = () => import(/* webpackChunkName: "follow_btn" */ '../section/follow_btn');
const baiguullaga_follow_btn = () => import(/* webpackChunkName: "baiguullaga_follow_btn" */ '../section/baiguullaga_follow_btn');
const brigad_follow_btn = () => import(/* webpackChunkName: "brigad_follow_btn" */ '../section/brigad_follow_btn');
export default {
    name: "ajilolgogch",
    components: {
        // "follow_btn": follow_btn,
        "baiguullaga_follow_btn": baiguullaga_follow_btn,
        "brigad_follow_btn": brigad_follow_btn,
    },
    data() {
        return {
            //loading & search conditions
            loading: true,
            selectTuluv: '',
            selectVilAjilgaa: '',
            selectAimagNiislel: '',
            selectSumDuureg: '',

            // baiguullaga search fill data
            ajil_olgogch_tuluw: [],
            vil_a_chiglel: [],
            aimag_niislel: [],
            sumduureg: [],

            //baiguullaga selected conditions
            baituluw: '',
            baichiglel: null,
            baiaimagniislel: null,
            baiduuregsum: null,
            value: 1,

            tolov_id: 1,
            uil_ajilgaa_id: 1,
            aimagniislel_id: 1,
            sum_duureg_id: 1,
            baiguullaga_lists: [],
            rate_search: 4,
            image_url_mbua: IMAGE_URL,
            brgd_srch_all: [],
            // current_page: 1,
            current_page_ga: 1,
            total_ga: 0,

            //BRIGAD VARIABLES
            bmodel1: null,
            bmodel2: null,
            bmodel3: null,
            bmodel4: null,

            // brigad search chosen data
            loadingBrgd: true,
            selectVilAjilgaaBrgd: '',
            selectAimagNiislelBrgd: '',
            selectSumDuuregBrgd: '',
            // data fill
            vil_a_chiglel_brgd: [],

            //brigad fill data
            brigad_list: [],

            //pagenation
            current_page_brgd: 1,
            total_brgd: 0,
            sumduuregBrgd: [],
            aimag_niislelBrgd: [],
        }
    },
    mounted() {
        this.getBaiguullagaSrchMulti();
        this.getAjilOlgogchTuluv();
        this.getUilAjilgaaChiglel();
        this.getAimagNiislel();
        this.getSumDuureg();
        this.getSumDuuregBrgd();

        //BRIGAD MOUNTS
        this.getUilAjilgaaChiglelBRGD();
        this.getBrigadSrchMultiCondition();

    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ]),
        SumDvvreg() {
            if (this.selectAimagNiislel) {
                return this.sumduureg.filter(sumdvvreg => sumdvvreg.aimagid == this.selectAimagNiislel);
            } else {
                return this.sumduureg;
            }
        },
        SumDvvregForBrgdSrch() {
            if (this.selectAimagNiislelBrgd) {
                return this.sumduuregBrgd.filter(sumdvvreg => sumdvvreg.aimagid == this.selectAimagNiislelBrgd);
            } else {
                return this.sumduuregBrgd;
            }
        },
    },
    methods: {
        // search functions
        getAjilOlgogchTuluv() {
            this.$apollo.query({query: AJIL_OLGOGCH_TULUV}).then(({data}) => {
                this.ajil_olgogch_tuluw = data.ds_ajil_olgohch_tolov_view;
            })
        },
        getUilAjilgaaChiglel() {
            this.$apollo.query({query: GET_UIL_AJIGAA_CHIGLEL}).then(({data}) => {
                this.vil_a_chiglel = data.ds_uil_ajilgaa_view;
            })
        },
        getAimagNiislel() {
            this.$apollo.query({query: GET_AIMAG_NIISLEL}).then(({data}) => {
                this.aimag_niislel = data.ds_aimag_view;
            })
        },
        getSumDuureg() {
            this.$apollo.query({query: GET_SUM_DUUREG_ALL}).then(({data}) => {
                this.sumduureg = data.ds_sum_view;
            })
        },
        getSumDuuregBrgd() {
            this.$apollo.query({query: GET_SUM_DUUREG_ALL}).then(({data}) => {
                this.sumduuregBrgd = data.ds_sum_view;
            })
        },
        //baiguullaga MULTI CONDITION SRCH
        getBaiguullagaSrchMulti() {
            console.log(this.selectTuluv);
            this.loading = true;
            if (this.selectTuluv || this.selectVilAjilgaa || this.selectAimagNiislel || this.selectSumDuureg) {
                this.$apollo.query({
                    query: GET_BAIGUULLAGA_SRCH_MULTI_CONDITION,
                    variables: {
                        page: this.current_page_ga, size: 16,
                        tolov_id: this.selectTuluv.toString(),
                        uajilgaa: this.selectVilAjilgaa.toString(),
                        aimagid: this.selectAimagNiislel.toString(),
                        sumduureg: this.selectSumDuureg.toString(),
                    }
                }).then(({data}) => {
                    this.baiguullaga_lists = data.paginate.ds_baiguullaga_view;
                    console.log("------this.baiguullaga_lists")
                    console.log(this.baiguullaga_lists)
                    this.total_ga = data.paginate.total;
                    setTimeout(() => {
                        this.loading = false;
                    })
                })
            } else {
                this.loading = true;
                this.$apollo.query({
                    query: GET_BAIGUULLAGA_SRCH_ALL_AOP,
                    variables: {page: this.current_page_ga, size: 16,}
                }).then(({data}) => {
                    this.baiguullaga_lists = data.paginate.ds_baiguullaga_view;
                    this.total_ga = data.paginate.total;
                    setTimeout(() => {
                        this.loading = false;
                    })
                })
            }
        },


        //***  BRIGAD FUNCTIONS ***     ***  BRIGAD FUNCTIONS ***     ***  BRIGAD FUNCTIONS ***

        getUilAjilgaaChiglelBRGD() {
            this.$apollo.query({query: GET_UIL_AJIGAA_CHIGLEL_BRIGAD}).then(({data}) => {
                this.vil_a_chiglel_brgd = data.lut_brigad_uil_ajilgaa_chiglel;
            })
        },

        getBrigadSrchMultiCondition() {
            this.loadingBrgd = true;
            if (this.selectVilAjilgaaBrgd || this.selectAimagNiislelBrgd || this.selectSumDuuregBrgd) {
                this.$apollo.query({
                    query: GET_BRIGAD_SRCH_MULTI_CONDITION,
                    variables: {
                        page: this.current_page_brgd, size: 16,
                        //uajilgaa: this.selectVilAjilgaa.toString(),
                        aimagid: this.selectAimagNiislelBrgd.toString(),
                        sumduureg: this.selectSumDuuregBrgd.toString(),
                    }
                }).then(({data}) => {
                    this.brigad_list = data.paginate.ds_brilgas_view;
                    this.total_brgd = data.paginate.total;
                    setTimeout(() => {
                        this.loadingBrgd = false;
                    })
                })
            } else {
                this.loadingBrgd = true;
                this.$apollo.query({
                    query: GET_BRIGAD_SRCH_ALL_AOP,
                    variables: {page: this.current_page_brgd, size: 16,}
                }).then(({data}) => {
                    this.brigad_list = data.paginate.ds_brilgas_view;
                    this.total_brgd = data.paginate.total;
                    setTimeout(() => {
                        this.loadingBrgd = false;
                    })
                })
            }
        },
        cleanFilterBaiguullaga() {
            this.selectTuluv = '';
            this.selectVilAjilgaa = '';
            this.selectAimagNiislel = '';
            this.selectSumDuureg = '';
            this.getBaiguullagaSrchMulti();
        },
        cleanFilterBrigad() {
            this.selectVilAjilgaaBrgd = '';
            this.selectAimagNiislelBrgd = '';
            this.selectSumDuuregBrgd = '';
            this.getBrigadSrchMultiCondition();
        },
        baiguullagaPaginate(page) {
            this.current_page_ga = page;
            this.getBaiguullagaSrchMulti();
        },
        brigadPaginate(page) {
            this.current_page_brgd = page;
            this.getBrigadSrchMultiCondition();
        }
    },

}
</script>

<style scoped>

</style>
